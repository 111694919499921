import { useState, useEffect } from "react"

import { getItem, setItem, delItem } from 'utils';

export function useCart() {
    // const [products, setProducts] = useState([])

    const addProduct = (item) => {
        let products = getProducts()
        let items = []

        let index = products.findIndex(p => String(p._id) === String(item._id))

        if(index > -1){
            products[index].amount += item.amount
            items = products
        }else{
            items = products.concat(item)
        }

        // setProducts(items)

        setItem('products', JSON.stringify(items))
    }

    const setProduct = (item) => {
        let products = getProducts()
        let items = []

        let index = products.findIndex(p => String(p._id) === String(item._id))

        if(index > -1){
            products[index].amount = item.amount
            items = products
        }

        // setProducts(items)

        setItem('products', JSON.stringify(items))
    }

    const getProducts = () => {
        let products = []

        let items = getItem('products')
        if(items){
            try {
                products = JSON.parse(items)
            }catch(error){
                console.log(error)
                delItem('products')
            }
        }

        // console.log(products.length)

        return products
    }

    const removeProduct = (item) => {
        let products = getProducts()
        let items = []

        let index = products.findIndex(p => String(p._id) === String(item._id))

        if(index > -1){
            products.splice(index, 1)
            items = products
        }

        // setProducts(items)

        setItem('products', JSON.stringify(items))
    }

    const cleanProducts = () => {
        delItem('products')
    }

    return {
        products : getProducts(),
        addProduct,
        getProducts,
        setProduct,
        removeProduct,
        cleanProducts,
    }
}