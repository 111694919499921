import React, { useEffect, useState } from "react"

// import { useCart } from "hooks/useCart"

import { server_url } from 'config'

import { getItem } from 'utils'

import{
    Box,
    ButtonGroup,
    Button,
    Grid,
} from '@mui/material'

import {
    mdiMagnify,
    mdiArrowLeft,
} from '@mdi/js'

import Icon from '@mdi/react';

import CategoryItem from "components/CategoryItem";
import ProductItem from "components/ProductItem";
import LoadingPage from "components/LoadingPage";
import TextField from "components/TextField";

const Products = () => {
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])
    // const [category, setCategory] = useState('')

    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')

    const fetchCategories = () => {
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            }
        }

        fetch(`${server_url}/categories/?active=true`, options)
        .then(response => {
            setLoading(false)

            return response.json()
        })
        .then(data => {
            setCategories(data)
        })
    }

    const getProducts = () => {
        if(search.length > 3){
            let options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Token' : getItem('token')
                }
            }

            fetch(`${server_url}/products/?active=true&search=${search}`, options)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setProducts(data)
            })
        }else{
            setProducts([])
        }
    }

    const getCategory = (_id) => {
        // setCategory(_id)
        
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            }
        }

        fetch(`${server_url}/products/?active=true&category=${_id}`, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setProducts(data)

            window.scrollTo(0, 0)
        })
    }

    const cleanSearch = () => {
        setSearch('')
        setProducts([])
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    return (
        <Box>
            { loading ?
                <LoadingPage />
            :
                <div>
                    <ButtonGroup variant="outlined" size="small" fullWidth >
                        <TextField value={search} label="Buscar" id="fullWidth" onChange={setSearch} fullWidth onSubmit={getProducts} onClean={cleanSearch} />
                        <Button sx={{ pr : 2 }} endIcon={<Icon path={mdiMagnify} size={1} />} fullWidth={false} onClick={getProducts} />
                    </ButtonGroup>
                    { products.length ?
                        <div>
                            <Button sx={{ mt : 2 }} color="primary" size="medium" variant="outlined" startIcon={<Icon path={mdiArrowLeft} size={1} />} onClick={cleanSearch}>Regresar</Button>
                            <Grid container spacing={2} sx={{ mt : 0 }} >
                                {products.map((product) => (
                                    <Grid item xs={12} md={6} >
                                        <ProductItem {...product} />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    :
                        <Grid container spacing={2} sx={{ mt : 0 }} >
                            {categories.map((category) => (
                                <Grid item xs={6} sm={4} md={3} >
                                    <CategoryItem onClick={getCategory} {...category}/>
                                </Grid>
                            ))}
                        </Grid>
                    }
                    
                </div>
            }
        </Box>
    )
}

export default Products