import React, { useEffect, useState } from "react"
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'

// import { useCart } from "hooks/useCart"

import { server_url } from 'config'

import { getItem } from 'utils'

import{
    Paper,
    Box,
    Typography,
    Button,
    Grid,
} from '@mui/material'

import LoadingPage from "components/LoadingPage";
import OrderItem from "components/OrderItem";
import DatePicker from "components/DatePicker";

const Orders = () => {
    const isMobile = useMediaQuery({ maxWidth: 1224 })
    const isDesktop = useMediaQuery({ minWidth: 1224 })

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const fetchOrders = (day) => {
        if(!day){
            day = date
        }

        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            }
        }

        fetch(`${server_url}/orders/?date=${day}`, options)
        .then(response => {
            setLoading(false)

            return response.json()
        })
        .then(data => {
            setOrders(data)
        })
    }

    const changeDate = (value) => {
        setDate(value)
        fetchOrders(value)
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <Box>
            { loading ?
                <LoadingPage />
            :
                <Box>
                    { isDesktop ?
                        <Paper sx={{ p : 2, mt : 2 }} >
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ flexGrow: 1 }} />
                                <DatePicker label="Fecha" value={date} onChange={changeDate} />
                            </Box>
                        </Paper>
                    : null }
                    { isMobile ?
                        <Box sx={{ mt : 1 }} >
                            <DatePicker label="Fecha" value={date} onChange={changeDate} />
                        </Box>
                    : null }
                    { orders.length < 1 ?
                        <Box sx={{ pt : 2 }} >
                            <Typography>No hay pedidos generados</Typography>
                        </Box>
                    :
                        <Grid container spacing={2} sx={{ mt : 0, mb : 2 }} >
                            {orders.map((order, i) => (
                                <Grid key={i} item xs={12} md={6} >
                                    <OrderItem {...order} />
                                </Grid>
                            ))}
                        </Grid>
                    }   
                </Box>
            }
        </Box>
    )
}

export default Orders