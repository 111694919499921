import { Link } from "react-router-dom";

import{
    Box,
    CircularProgress
} from '@mui/material'

const CustomButton = (props) => {
    return (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', marginRight: '-50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
        </Box>
    )
}

export default CustomButton