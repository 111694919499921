import { useState } from "react"

import { useNavigate } from "react-router-dom"
import { useSnackbar } from 'notistack'

import withSession from "hocs/withSession"

import { server_url } from 'config'
import { getItem, setItem } from 'utils';

import {
    Box,
    Paper,
} from '@mui/material'

import AppLayout from 'layout/AppLayout'
import TextField from 'components/TextField'
import Button from 'components/Button'

const title = 'Login'

const Login = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [account, setAccount] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const login = (e) => {
        if(e){
            e.preventDefault()
        }

        let data = {
            account : account,
            username : username,
            password : password,
        }

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        }
        
        fetch(`${server_url}/clients/login`, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            if(data.token){
                setItem('token', data.token)

                navigate('/')
            }else{
                enqueueSnackbar(data.error)
            }
        })
    }

    return (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', marginRight: '-50%', transform: 'translate(-50%, -50%)', minWidth : 300 }}>
            <Paper>
                <Box sx={{ flexGrow: 1, padding : 2, marginBottom : 2 }}>
                    <form noValidate onSubmit={login}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField sx={{ mb : 2 }} value={account} onChange={setAccount}   label='Cuenta' />
                            <TextField sx={{ mb : 2 }} value={username} onChange={setUsername} label='Usuario' />
                            <TextField sx={{ mb : 2 }} value={password} onChange={setPassword} label='Contraseña' type='password' onSubmit={login} />
                        </Box>
                    </form>
                    <Button onClick={login} >Entrar</Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default withSession(Login)