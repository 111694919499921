import { useState, useEffect } from "react"
import { getItem, setItem, delItem } from 'utils';

export function useFragment() {
    const [fragment, setFragment] = useState(getItem('fragment'))

    const getFragment = () => {
        return getItem('fragment')
    }

    const setFragmentData = (data) => {
        setFragment(data)
        setItem('fragment', data)
    }

    useEffect(() => {
        console.log(fragment)
    }, [fragment])

    return {
        fragment : getFragment(),
        setFragment : setFragmentData,
    }
}