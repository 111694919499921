import { Link } from "react-router-dom";

import { menu } from 'config'

import{
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material'

import Icon from '@mdi/react';

const AppDrawer = (props) => {

    return (
        <List>
            {
                menu.map((item, i) => {
                    return (
                        <ListItem key={i} button onClick={() => props.handleMenuClick(item.fragment) } >
                            <ListItemIcon><Icon path={item.icon} size={1} color="gray" /></ListItemIcon>
                            <ListItemText>{item.title}</ListItemText>
                        </ListItem>
                    )

                })
            }
        </List>

    )
}

export default AppDrawer;