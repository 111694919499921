import moment from 'moment'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import{
    Grid,
} from '@mui/material'

const CustomDatePicker = (props) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <DatePicker
                label={props.label}
                slotProps={{ textField: { variant: 'outlined', fullWidth: true } }}
                value={moment(props.value)}
                onChange={ (newValue) => props.onChange(newValue.format('YYYY-MM-DD')) }
                //disableOpenPicker={props.readonly}
                // readOnly={props.readonly}
                format="YYYY-MM-DD"
            />
        </Grid>
    )
}

export default CustomDatePicker