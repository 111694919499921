import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/es-mx';

import { SnackbarProvider, closeSnackbar } from 'notistack'
import Icon from '@mdi/react';

import {
    mdiClose,
} from '@mdi/js'

const theme = createTheme({
    palette: {
        primary: {
            main: "#69AFFF",
        },
    },
    components : {
        MuiBottomNavigationAction : {
            styleOverrides : {
                "root": {
                    "&.Mui-selected": {
                        "color": "white",
                        "fontWeight" : "bold",
                    }
                }
            }
        }
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
            <SnackbarProvider
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            action={<Icon path={mdiClose} size={1} onClick={() => { closeSnackbar() } } />}
            variant='error'
            autoHideDuration={3000}
            hideIconVariant={true}
            >
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </SnackbarProvider>
        </LocalizationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
