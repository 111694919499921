import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { useSnackbar } from 'notistack'

import{
    Paper,
    ButtonGroup,
    IconButton,
    Button,
    Typography,
    Box,
    ButtonBase,
} from '@mui/material'

import Icon from '@mdi/react';

import {
    mdiMinus,
    mdiPlus,
    mdiCartPlus,
    mdiCartMinus,
} from '@mdi/js'

import { useTheme, styled } from '@mui/material/styles';

import { server_url } from 'config'
import { currencyFormat, capitalize } from 'utils'

import { useCart } from "hooks/useCart"

const paperStyle = {
    padding: 0,
    marginTop: 0,
}

const buttonStyle = {
    padding: "0px 5px 0px 5px",
    margin: "0px",
}

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    width :200,
    height: 200,
    [theme.breakpoints.down('sm')]: {
        // width: '100% !important', // Overrides inline-style
        // height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}))

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
})

const ProductItem = (props) => {
    const theme = useTheme()
    const { addProduct, setProduct, removeProduct } = useCart()
    const { enqueueSnackbar } = useSnackbar()

    const [elevation, setElevation] = useState(1)
    const [amount, setAmount] = useState(props.amount || 1)

    const isDesktop = useMediaQuery({ minWidth: 1224 })

    const textStyle = {
        border: "1px solid",
        borderColor : theme.palette.primary.main,
        padding: "3px 10px 0px 10px",
        margin: "0px  -1px 0px 0px",
        width: "50px",
        color : theme.palette.primary.main,
    }

    const sumItem = () => {
        let new_amount = amount + 1
        
        setAmount(new_amount)

        if(props.cart){
            let item = {
                _id : props._id,
                amount : new_amount,
            }

            setProduct(item)
        }
    }

    const restItem = () => {
        if(amount > 1){
            let new_amount = amount - 1

            setAmount(new_amount)

            if(props.cart){
                let item = {
                    _id : props._id,
                    amount : new_amount,
                }

                setProduct(item)
            }
        }
    }

    const addItem = () => {
        let item = {
            _id : props._id,
            code : props.code,
            amount : amount,
            name : props.name,
            price : props.price,
        }

        if(amount > 0){
            addProduct(item)
            // setAmount(1)

            enqueueSnackbar('Producto agregado!', { variant : 'success', autoHideDuration : 1000 })
        }
    }

    const removeItem = () => {
        let item = {
            _id : props._id,
        }

        removeProduct(item)
        props.onDelete()
    }

    return (
        <Box key={props._id}>
            <Paper style={paperStyle} elevation={isDesktop ? elevation : 1} onMouseOver={() => setElevation(10)} onMouseOut={() => setElevation(1)}>
                <Box sx={{ display: 'flex', flexDirection: 'row', p : 1 }}>
                    <ImageButton style={{ width: "100px", height: '100px' }} >
                        <ImageSrc style={{ backgroundImage: `url('${props.image ? props.image : `${server_url}/images/not_found.png` }')` }} />
                    </ImageButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pl : 1 }}>
                        <Typography>{capitalize(props.name)}</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', p : 0 }}>
                        <ButtonGroup sx={{ flexGrow: 1 }} variant="outlined" size="small">
                            <Button     style={buttonStyle} startIcon={<Icon path={mdiMinus} size={.8} />}   onClick={restItem} disabled={amount < 2} ></Button>
                            <Typography style={textStyle}   disabled >{amount}</Typography>
                            <Button     style={buttonStyle} endIcon={<Icon path={mdiPlus} size={.8} />}      onClick={sumItem} ></Button>
                        </ButtonGroup>
                        { props.cart ?
                            <Button variant="outlined" style={buttonStyle} startIcon={<Icon path={mdiCartMinus} size={.9} />} onClick={removeItem} >Remover</Button>
                        :
                            <Button variant="outlined" style={buttonStyle} startIcon={<Icon path={mdiCartPlus} size={.9} />} onClick={addItem} >Añadir</Button>
                        }
                        <Box sx={{ flexGrow: 10 }} />
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default ProductItem