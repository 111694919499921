import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom"

import { delItem } from 'utils';

import { useSession } from "hooks/useSession"

import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    Badge,
    Divider,
    Button,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material'

import {
    mdiMenu,
    mdiAccountCircle,
    mdiLogout,
} from '@mdi/js'

import Icon from '@mdi/react';

const AppHeader = (props) => {
    const navigate = useNavigate()
    const { user } = useSession()

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logout = () => {
        delItem('token')
        delItem('products')

        navigate('/login')
    }

    useEffect(() => {
        
    }, [])
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" >
                <Toolbar variant="dense">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Pedidos SuperTPV - {props.title}
                    </Typography>
                    <Tooltip title="Cuenta">
                        <IconButton
                            onClick={handleClick}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                        >
                            <Icon path={mdiAccountCircle} size={1} color="white" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                    >
                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <Icon path={mdiLogout} size={1} color="gray" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default AppHeader;