import React, { useEffect, useState } from "react"

import withSession from "hocs/withSession"

// import { useCart } from "hooks/useCart"
import { useFragment } from "hooks/useFragment"

import { server_url } from 'config'

// import { currencyFormat, capitalize } from 'utils'

import{
    Paper,
    Box,
    Typography,
} from '@mui/material'

// import ProductList from "components/ProductList";

import AppLayout from 'layout/AppLayout'

import Products from 'fragments/products'
import Cart from 'fragments/cart'
import Orders from 'fragments/orders'

const Home = () => {
    const { fragment, setFragment } = useFragment()

    const [title, setTitle] = useState('Products')

    const changeFragment = (fragment) => {
        setFragment(fragment)

        switch(fragment){
            case 'products':{
                setTitle('Productos')
                break
            }
            case 'cart':{
                setTitle('Carrito')
                break
            }
            case 'orders':{
                setTitle('Pedidos')
                break
            }
        }
    }

    useEffect(() => {
        // console.log(fragment)
    }, [fragment])

    return (
        <AppLayout title={title} handleMenuClick={changeFragment}>
            {
                {
                    'products': <Products />,
                    'cart': <Cart setFragment={changeFragment} />,
                    'orders' : <Orders />,
                }[fragment]
            }
        </AppLayout>
    )
}

export default withSession(Home)
// export default Home