import { useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"

import { useSession } from "hooks/useSession"

const withSession = (Component) => {
    return (props) => {
        const { token, user } = useSession()
        const navigate = useNavigate()
        const location = useLocation()

        console.log(user)

        useEffect(() => {
            if(!token){
                if(location.pathname !== '/login'){
                    navigate('/login')
                }
            }else{
                if(location.pathname === '/login'){
                    navigate('/')
                }
            }

        }, [token, location, navigate])

        return <Component user={user} {...props} />
    }
}

export default withSession