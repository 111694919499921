import { useMediaQuery } from 'react-responsive'

import { 
    CssBaseline,
    Box,
    BottomNavigation,
    Paper,
} from '@mui/material'

import {
    Root,
    Header,
    EdgeSidebar,
    SidebarContent,
    Content,
    Footer,
} from "@mui-treasury/layout"

import AppBottom from 'layout/AppBottom'
import AppHeader from 'layout/AppHeader'
import AppDrawer from 'layout/AppDrawer'

const layout_config = {
    header: {
        config: {
            xs: {
                position: "sticky",
                height: 48,
                clipped: {
                    leftEdgeSidebar: true,
                },
            },
        },
    },
    leftEdgeSidebar: {
        config: {
            sm: {
                variant: "permanent",
                width: 256,
                headerMagnetEnabled: true,
            },
        },
    },
}

const AppLayout = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 1224 })
    const isDesktop = useMediaQuery({ minWidth: 1224 })

    const layout_config = {
        header: {
            config: {
                xs: {
                    position: "sticky",
                    height: 48,
                    clipped: {
                        leftEdgeSidebar: true,
                    },
                },
            },
        },
        leftEdgeSidebar: {
            config: {
                sm: {
                    variant: "permanent",
                    width: isDesktop ? 256 : 0,
                    headerMagnetEnabled: true,
                },
            },
        },
    }

    return (
        <div>
            <CssBaseline />
            <Root scheme={layout_config} >
                <Header>
                    <AppHeader title={props.title}/>
                </Header>
                { isDesktop ?
                    <EdgeSidebar anchor="left">
                        <SidebarContent>
                            <AppDrawer handleMenuClick={props.handleMenuClick}/>
                        </SidebarContent>
                    </EdgeSidebar>
                : null}
                <Content>
                    <Box p={2} sx={{ paddingBottom : 10 }} >
                        { props.children }
                    </Box>
                </Content>
                { isMobile ?
                    <Footer>
                        <AppBottom handleMenuClick={props.handleMenuClick} />
                    </Footer>
                : null}
            </Root>
        </div>
    )
}

export default AppLayout;