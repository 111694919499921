import React, { useEffect, useState } from "react"
import { useMediaQuery } from 'react-responsive'
import { useSnackbar } from 'notistack';

import { useCart } from "hooks/useCart"

import { server_url } from 'config'

import { getItem } from 'utils'

import{
    Paper,
    Box,
    Typography,
    Grid,
} from '@mui/material'

import ProductItem from "components/ProductItem";
import Button from 'components/Button'

const Cart = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const isMobile = useMediaQuery({ maxWidth: 1224 })
    const isDesktop = useMediaQuery({ minWidth: 1224 })

    const { getProducts, cleanProducts } = useCart()

    const [bloomies, setBloomies] = useState([])
    const [products, setProducts] = useState(getProducts())

    const sendOrder = () => {
        let data = {
            products : getProducts()
        }

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            },
            body: JSON.stringify(data),
        }

        fetch(`${server_url}/orders/save`, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            cleanProducts()
            setProducts(getProducts())

            enqueueSnackbar('Pedido enviado!', { variant : 'success', autoHideDuration : 1000 })

            props.setFragment('orders')
        })
        .catch((error) => {
            enqueueSnackbar('Error al enviar el pedido!')
        })
    }

    const onDelete = () => {
        setProducts(getProducts())
    }

    useEffect(() => {
        // fetchBloomies()
    }, [])

    return (
        <Box sx={{ mt : -2 }}>
            { isDesktop ?
                <Paper sx={{ p : 2, mt : 2 }} >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button onClick={sendOrder} fullWidth={false} disabled={products.length < 1} >Enviar pedido</Button>
                    </Box>
                </Paper>
            : null }
            { products.length < 1 ?
                <Box sx={{ pt : 2 }} >
                    <Typography>No hay productos agregados</Typography>
                </Box>
            :
                <Grid container spacing={2} sx={{ mt : 0, mb : 2 }} >
                    {products.map((product) => (
                        <Grid item xs={12} md={6} >
                            <ProductItem cart onDelete={onDelete} {...product} />
                        </Grid>
                    ))}
                </Grid>
            }   
            { isMobile ?
                <Paper sx={{ p : 2, mt : 2 }} >
                    <Button onClick={sendOrder} disabled={products.length < 1} >Enviar pedido</Button>
                </Paper>
            : null}
        </Box>
    )
}

export default Cart