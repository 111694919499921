import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

import{
    Paper,
    ButtonGroup,
    IconButton,
    Button,
    Typography,
    Box,
    ButtonBase,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
} from '@mui/material'

import Icon from '@mdi/react';

import {
    mdiChevronRight,
} from '@mdi/js'

import { useTheme, styled } from '@mui/material/styles';

import { server_url } from 'config'
import { currencyFormat, capitalize, humanize, titleCase, upperCase } from 'utils'

import { useCart } from "hooks/useCart"

const paperStyle = {
    padding: 0,
    marginTop: 0,
}

const buttonStyle = {
    padding: "0px 5px 0px 5px",
    margin: "0px",
}

const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<Icon path={mdiChevronRight} size={.9} />}
        {...props}
    />
    ))(({ theme }) => ({
        backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const OrderItem = (props) => {
    const [expanded, setExpanded] = useState('panel1')

    const [elevation, setElevation] = useState(1)
    const isDesktop = useMediaQuery({ minWidth: 1224 })

    const handleChange = (panel) => (event, newExpanded) => {
        console.log(panel)

        setExpanded(newExpanded ? panel : false)
    }

    return (
        <Box key={props._id}>
            <Paper style={paperStyle} elevation={isDesktop ? elevation : 1} onMouseOver={() => setElevation(10)} onMouseOut={() => setElevation(1)}>
                <Accordion expanded={expanded === `panel${props.number}` } onChange={handleChange(`panel${props.number}`)}>
                    <AccordionSummary aria-controls={`panel${props.number}d-content`} id={`panel${props.number}d-header`} >
                        <Typography>Pedido No. {props.number}: {props.total_products} productos, enviado { capitalize(humanize(props.date))}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            props.categories.map((category, i) => (
                                <table key={i} style={{ width : '100%', borderCollapse: 'collapse' }}>
                                    <tr><th colspan={3}>{ titleCase(category.name) }</th></tr>
                                    {
                                        category.products.map((product, p) => (
                                            <tr key={p}>
                                                <td>
                                                    {upperCase(product.code)}
                                                </td>
                                                <td>
                                                    {titleCase(product.name)}
                                                </td>
                                                <td style={{ textAlign : 'right' }}>
                                                    {product.amount}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            ))
                        }
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </Box>
    )
}

export default OrderItem