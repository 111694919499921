import { useState, useEffect } from "react"
import { Link } from "react-router-dom";

import { useFragment } from "hooks/useFragment"
// import { useCart } from "hooks/useCart"

import { menu } from 'config'

import {
    Paper,
    BottomNavigation,
    BottomNavigationAction,
} from '@mui/material'

import {
    mdiTextSearch,
    mdiCartVariant,
    mdiOrderBoolDescendingVariant,
} from '@mdi/js'

import Icon from '@mdi/react';

const style = {
    bgcolor : '#69AFFF',
    selected : {
        color : 'red'
    }
}

const action_style = {
    color : 'white',
}

const AppHeader = (props) => {
    const { fragment } = useFragment()

    useEffect(() => {
        
    }, [])
    
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <BottomNavigation
                value={fragment}
                onChange={(event, value) => {
                    props.handleMenuClick(value)
                }}
                sx={style}
            >
                {
                    menu.map((item, i) => {
                        return (
                            <BottomNavigationAction value={item.fragment} key={i} label={item.title} showLabel={true} icon={<Icon path={item.icon} size={1} color="white" />} sx={action_style} />
                        )
                    })
                }
            </BottomNavigation>
        </Paper>
    )
}

export default AppHeader;