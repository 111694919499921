const {
    mdiTextSearch,
    mdiCartVariant,
    mdiOrderBoolDescendingVariant,
} = require('@mdi/js')

//SERVER
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    exports.server_url = 'http://api.localhost:5001'
} else {
    exports.server_url = 'https://api.supertpv.com'
    console.log = function () {}
}

exports.layout_config = {
    "navAnchor": "left",
    "navVariant": {
        "xs": "temporary",
        "sm": "permanent",
        "md": "permanent"
    },
    "navWidth": 256,
    "collapsible": {
        "xs": false,
        "sm": false,
        "md": false
    },
    "collapsedWidth": {
        "xs": 64,
        "sm": 64,
        "md": 64
    },
    "clipped": {
        "xs": true,
        "sm": false,
        "md": false
    },
    "headerPosition": {
        "xs": "fixed",
        "sm": "fixed",
        "md": "fixed"
    },
    "squeezed": {
        "xs": true,
        "sm": true,
        "md": true
    },
    "footerShrink": {
        "xs": true,
        "sm": true,
        "md": true
    }
}

exports.menu = [
    {
        title : 'Productos',
        icon : mdiTextSearch,
        fragment : 'products',
    },
    {
        title : 'Carrito',
        icon : mdiCartVariant,
        fragment : 'cart',
    },
    {
        title : 'Pedidos',
        icon : mdiOrderBoolDescendingVariant,
        fragment : 'orders',
    },
    // {
    //     text: 'Configuration',
    //     sections: [
    //         // { text : 'Gameplay Modes', icon : <GamepadIcon />, url : '/game_modes' },
    //         // { text : 'Maps', icon : <MapIcon />, url : '/maps' },
    //         { text : 'Regions', icon : <ListIcon />, url : '/regions' },
    //     ],
    // },
    // {
    //     text: 'Tournaments',
    //     sections: [
    //         // { text : 'Gameplay Modes', icon : <GamepadIcon />, url : '/game_modes' },
    //         // { text : 'Maps', icon : <MapIcon />, url : '/maps' },
    //         { text : 'List', icon : <ListIcon />, url : '/tournaments' },
    //     ],
    // },
    // {
    //     text: 'Shop',
    //     sections: [
    //         // { text : 'Gameplay Modes', icon : <GamepadIcon />, url : '/game_modes' },
    //         // { text : 'Maps', icon : <MapIcon />, url : '/maps' },
    //         { text : 'Bloomies', icon : <SwapHorizIcon />, url : '/bloomies' },
    //         { text : 'Categories', icon : <ViewModuleIcon />, url : '/categories' },
    //         { text : 'Products', icon : <DescriptionIcon />, url : '/products' },
    //     ],
    // },
]