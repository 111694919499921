import React, { Component } from "react"
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom"

import Home from "pages/home"
import Login from "pages/login"

function App() {
    return (
        <Router basename={'/'} >
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path='/login' element={<Login/>} />
            </Routes>
        </Router>
    );
}

export default App;