import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

import{
    Paper,
    ButtonGroup,
    IconButton,
    Button,
    Typography,
    Box,
    ButtonBase,
} from '@mui/material'

import {
    Remove as MinusIcon,
    Add as PlusIcon,
    ShoppingCartOutlined as CartIcon,
    DeleteForever as TrashIcon,
} from "@mui/icons-material"

import { useTheme, styled } from '@mui/material/styles';

import { server_url } from 'config'
import { currencyFormat, capitalize } from 'utils'

// import { useCart } from "hooks/useCart"

const paperStyle = {
    padding: 0,
    margin: 0,
}

const buttonStyle = {
    padding: "0px 5px 0px 5px",
    margin: "0px",
}

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    width: '100% !important',
    height: 200,
    // [theme.breakpoints.down('sm')]: {
    //     width: '100% !important', // Overrides inline-style
    //     height: 120,
    // },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}))

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
})

const CategoryItem = (props) => {
    const navigate = useNavigate()

    const [elevation, setElevation] = useState(1)

    const isDesktop = useMediaQuery({ minWidth: 1224 })

    const goToCategory = (_id) => {
        console.log(_id)

        props.onClick(_id)
    }

    return (
        <Box key={props._id}>
            <Paper style={paperStyle} elevation={isDesktop ? elevation : 1} onMouseOver={() => setElevation(10)} onMouseOut={() => setElevation(1)} onClick={() => goToCategory(props._id)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', p : 1 }}>
                    <ImageButton style={{ width: "140px", height: '140px' }}>
                        <ImageSrc style={{ backgroundImage: `url('${props.image ? props.image : `${server_url}/images/not_found.png` }')` }} />
                    </ImageButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pt : 1 }}>
                        <Typography>{capitalize(props.name)}</Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default CategoryItem