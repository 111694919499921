import { Link } from "react-router-dom";

import{
    Button,
} from '@mui/material'

const CustomButton = (props) => {
    return (
        <Button sx={{ color : 'white' }} type="submit" variant="contained" color="primary" onClick={props.onClick} component={Link} to={ props.to } fullWidth={props.fullWidth != undefined ? props.fullWidth : true}
            disabled={props.disabled}
        >
            { props.children }
        </Button>
    )
}

export default CustomButton