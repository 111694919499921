import{
    Grid,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material'

import {
    mdiBackspaceOutline,
} from '@mdi/js'

import Icon from '@mdi/react';

const CustomTextField = (props) => {
    return (
        <TextField sx={props.sx} id="standard-basic" value={props.value} label={props.label} type={props.type} inputProps={{min : props.min, max: props.max}} variant="outlined"
            fullWidth={props.fullWidth}
            multiline={props.multiline ? props.multiline : null}
            onChange={props.onChange ? (event) => { props.onChange(event.target.value) } : null}
            onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                    // Do code here
                    ev.preventDefault();

                    props.onSubmit()
                }
            }}
            InputProps={{
                readOnly: props.readonly,
                endAdornment : ( props.onClean ?
                    <InputAdornment position="end">
                        <IconButton
                            // aria-label="toggle password visibility"
                            onClick={props.onClean}
                            // onMouseDown={handleMouseDownPassword}
                        >
                            <Icon path={mdiBackspaceOutline} size={1} color="gray" />
                        </IconButton>
                    </InputAdornment>
                : null)
            }}
        />
    )
}

export default CustomTextField